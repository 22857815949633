import { GitHub, LinkedIn, Email } from "@material-ui/icons";

import "../css/EndBar.css";

import ParticleComponent from "./ParticleComponent";

export default function EndBar(props) {

  return (
    <div
      style={{
        width: "100%",
        height: props.heightOfEndBar,
        background:
          "linear-gradient(234deg, rgba(0,0,0,0.8102591378348214) 0%, rgba(122,126,53,1) 25%, rgba(233,232,96,1) 59%, rgba(251,252,205,1) 100%)",
      }}
    >
      <ParticleComponent
        width={"100%"}
        height={props.heightOfEndBar}
        limit={55}
      ></ParticleComponent>
      <div style={{ marginTop: "70px", width: "100%", position: "absolute" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div>
            <GitHub
              className="icon-right-space"
              style={{ cursor: "pointer", fontSize: 60 }}
              onClick={() => {
                window.location.href = "https://github.com/cezar007dead";
              }}
            ></GitHub>
          </div>
          <div>
            <LinkedIn
              className="icon-right-space"
              style={{ cursor: "pointer", fontSize: 60 }}
              onClick={() => {
                window.location.href =
                  "https://www.linkedin.com/in/gurgen-meliksetyants-00aa71167/";
              }}
            ></LinkedIn>
          </div>
          <div>
            <Email
              style={{ cursor: "pointer", fontSize: 60 }}
              onClick={() => {
                window.location.href =
                  "mailto:gurgen.meliksetyants@gmail.com?subject=Let's Talk!";
              }}
            ></Email>
          </div>
        </div>
      </div>
    </div>
  );
}
