import React, { useEffect, useState } from "react";

import { GitHub, LinkedIn, Email } from "@material-ui/icons";
import "../css/MainPage.css";

import picture_gurgen from "../staticResources/Gurgen.jpg";
import logo_js from "../staticResources/logo_js.png";
import logo_golang from "../staticResources/logo_golang.png";
import logo_C from "../staticResources/logo_C.png";
import earthquakehome_page from "../staticResources/earthquakehome_pages.png";
import resume_gm from "../staticResources/resume_gm.pdf";
import resume_gm_picture from "../staticResources/resume_gm_picture.png";

import ParticleComponent from "./ParticleComponent";
import EndBar from "./EndBar";
import PersonalInformationForm from "./PersonalInformationForm";

export default function MainPage(props) {
  // const heightOfTitleBar = "700px";
  const heightOfEndBar = "250px";

  const [heightOfTitleBar, setHeightOfTitleBar] = useState("700px");


  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 800) {
        setHeightOfTitleBar("400px");
      }else{
        setHeightOfTitleBar("700px");
      }
    }
    if (window.innerWidth < 800) {
      setHeightOfTitleBar("400px");
    }else{
      setHeightOfTitleBar("700px");
    }

    window.addEventListener("resize", handleResize);
  });
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: heightOfTitleBar,
          background:
            "linear-gradient(234deg, rgba(0,0,0,0.8102591378348214) 0%, rgba(122,126,53,1) 25%, rgba(233,232,96,1) 59%, rgba(251,252,205,1) 100%)",
        }}
      >
        <ParticleComponent
          width={"100%"}
          height={heightOfTitleBar}
          limit={80}
        ></ParticleComponent>
        <div className="form-on-background scale-in-center">
          <img
            src={picture_gurgen}
            style={{
              width: "60%",
              border: "2px solid #293347",
              borderRadius: "15px",
              marginBottom: "25px",
            }}
            alt=""
          />
          <h1 className="title-text">Gurgen</h1>
          <h1 className="title-text">Meliksetyants</h1>
        </div>
      </div>
      <div>
        <h1
          style={{
            color: "#202020",
            textAlign: "center",
            fontFamily: '"Times New Roman", serif"',
          }}
        >
          My Main Programing Languages
        </h1>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            marginTop: "40px",
            // margin: "auto",
            textAlign: "center",
          }}
        >
          <div style={{ width: "33.3%" }}>
            <img
              src={logo_C}
              style={{ height: "63px", margin: "auto", display: "block" }}
            />
            <span style={{ fontSize: "22px" }}>C#</span>
          </div>
          <div style={{ width: "33.3%" }}>
            <img
              src={logo_golang}
              style={{ height: "75px", margin: "auto", display: "block" }}
            />
            <span style={{ fontSize: "22px" }}>Golang</span>
          </div>
          <div style={{ width: "33.3%" }}>
            <img
              src={logo_js}
              style={{ height: "63px", margin: "auto", display: "block" }}
            />
            <span style={{ fontSize: "22px" }}>Javascript</span>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <div className="split-bar"></div>
        <h1
          style={{
            fontSize: "42px",
            fontFamily: "'Times New Roman', Times, serif",
            fontWeight: "bold",
            color: "#202020",
          }}
        >
          About Me
        </h1>

        <div
          style={{
            textAlign: "center",
            marginTop: "15px",
            marginLeft: "30px",
            paddingBottom: "30px",
            marginRight: "30px",
            color: "#202020",
          }}
        >
          <div style={{ fontSize: "22px" }}>
            Greetings! I am a Full Stack Developer. I was studying at University
            in my born country. Took part in Olympic Programming. I also was
            working there for a startup company. Then the life changed and I
            moved to USA. Here i went to bootcamp, took second place at
            hackathon. When I was done with bootcamp i found Job (For more
            information about my job history please check my resume). I love
            coding. I really want to continue growing in this sphere. It will be
            awesome in the future to work in cyber security company and do
            blockchain.
          </div>
        </div>
      </div>
      {/* Project and Resume */}
      <div style={{ width: "100%" }}>
        <div
          style={{
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <div
            className="flex-width"
            style={{ minWidth: "300px", maxWidth: "100%" }}
          >
            <div className="split-bar"></div>
            <h2
              style={{
                color: "black",
                textAlign: "center",
                fontFamily: '"Times New Roman", serif"',
              }}
            >
              My Personal Project
            </h2>
            <img
              onClick={() => {
                window.location.href = "https://www.earthquakehome.com/";
              }}
              className="flex-project-picture"
              style={{ cursor: "pointer" }}
              src={earthquakehome_page}
              alt=""
            />
            <br />
            <a
              href="https://www.earthquakehome.com/"
              style={{ fontWeight: 700, color: "#4c82d9" }}
            >
              www.earthquakehome.com
            </a>
            <div style={{ fontSize: "20px", margin: "55px" }}>
              EarthQuake Map is a website that will show you earthquakes and
              other information. You will be able to see latest earthquakes that
              happened in the world. For more information please go to website
              and navigate to About page.
            </div>
          </div>
          <div
            className="flex-width"
            style={{ minWidth: "300px", maxWidth: "100%" }}
          >
            <div className="split-bar"></div>
            <h2
              style={{
                color: "black",
                textAlign: "center",
                fontFamily: '"Times New Roman", serif"',
              }}
            >
              Resume
            </h2>
            <img
              onClick={() => {
                window.location.href = resume_gm;
              }}
              className="flex-resume-picture"
              style={{ cursor: "pointer", border: "2px solid #293347" }}
              src={resume_gm_picture}
            />
          </div>
        </div>
      </div>
      <PersonalInformationForm />
      <EndBar heightOfEndBar={heightOfEndBar} />
    </div>
  );
}
