import React, { PureComponent, useState } from "react";
import Particles from "react-particles-js";
import "../css/Particles.css";

import logo_react from "../staticResources/logo_react.png";
import logo_golang from "../staticResources/logo_golang.png";
import logo_aws from "../staticResources/logo_aws.png";
import logo_github from "../staticResources/logo_github.png";
import logo_C from "../staticResources/logo_C.png";
import logo_postman from "../staticResources/logo_postman.png";


export default function ParticleComponent(props) {
  return (
    <>
      <Particles
        // id="particles-js"
        width={props.width}
        height={props.height}
        style={{ position: "absolute"}}
        params={{
          particles: {
            number: {
              value: 40,
              limit: props.limit,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              direction: "top",
              speed: 3,
              out_mode: "out",
            },
            shape: {
              type: ["image"],
              image: [
                {
                  src: logo_react,
                  height: 20,
                  width: 20,
                },
                {
                  src: logo_golang,
                  height: 15,
                  width: 10,
                },
                {
                  src: logo_aws,
                  height: 10,
                  width: 15,
                },
                {
                  src: logo_github,
                  height: 10,
                  width: 10,
                },
                {
                  src: logo_C,
                  height: 10,
                  width: 9,
                },
                {
                  src: logo_postman,
                  height: 10,
                  width: 10,
                },
              ],
            },
            color: {
              value: "#CCC",
            },
            size: {
              value: 18,
              random: true,
              anim: {
                enable: true,
                speed: 4,
                size_min: 10,
                sync: false,
              },
            },
          },
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
            },
          },
          retina_detect: true,
        //   background: { color: "#286aa4" },
        }}
      ></Particles>
    </>
  );
}
