// import './App.css';

import MainPage from "./components/MainPage";

function App() {
  return (
    <MainPage></MainPage>
  );
}

export default App;
