import { LocationOn, PhoneOutlined, Email } from "@material-ui/icons";

import "../css/PersonalInformationForm.css";

export default function PersonalInformationForm(props) {
  return (
    <div>
      <div className="information-form">
        <h2
          style={{
            padding: "5px",
            fontFamily: '"Times New Roman", serif"',
          }}
        >
          If you want to talk here is my contact information
        </h2>
        <div className="icon-with-text">
          <LocationOn className="icon-color" fontSize="large" />
          <span className="body-text">Los Angeles, CA</span>
        </div>
        <div className="icon-with-text">
          <PhoneOutlined className="icon-color" fontSize="large" />
          <span className="body-text">{process.env.REACT_APP_PHONE}</span>
        </div>
        <div className="icon-with-text">
          <Email className="icon-color" fontSize="large" />
          <span className="body-text">gurgen.meliksetyants@gmail.com</span>
        </div>
        <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
          <a
            href="mailto:gurgen.meliksetyants@gmail.com?subject=Let's Talk!"
            className="btn-grad"
          >
            Lets Talk!
          </a>
        </div>
      </div>
    </div>
  );
}
